import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazy-load';
import classNames from 'classnames';
import { ThemeContext, themes } from '../../themes/ThemeContext';
import stylesNordr from './Card.nordr.module.scss';
import stylesFolkhem from './Card.folkhem.module.scss';

const getStyles = (theme) => ({
        [themes.NORDR]: stylesNordr,
        [themes.FOLKHEM]: stylesFolkhem,
    }[theme]
);

class Card extends React.PureComponent {
    render() {
        const { title, text, link, image, modifier } = this.props;

        const theme = this.context;
        const styles = getStyles(theme);

        const Tag = link ? 'a' : 'div';
        let attrs = {};
        if (link) {
            attrs.href = link.href;
        }

        const className = classNames(
            'Card',  // Note: Are kept for class name tracking
            styles["Card"], {
            [styles["Card--"+modifier]]: !!modifier,
        });

        return (
            <Tag className={className} {...attrs}>
                <LazyLoad
                    offset={400}
                    throttle={150}
                    debounce={false}
                    className={styles["Card__LazyImgWrapper"]}>
                    <div
                        className={styles["Card__Image"]}
                        style={
                            image && { backgroundImage: `url(${image.src})` }
                        }
                    />
                </LazyLoad>
                <div className={styles["Card__Content"]}>
                    {title && <h2 className={styles["Card__Title"]}>{title}</h2>}
                    <div
                        className={styles["Card__text"]}
                        dangerouslySetInnerHTML={{ __html: text }}
                    />
                </div>
            </Tag>
        );
    }
}

Card.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.object,
    image: PropTypes.object,
    modifier: PropTypes.string,
};

Card.defaultProps = {
    image: {
        renditions: {},
    },
    modifier: '',
};

Card.contextType = ThemeContext;

export default Card;
