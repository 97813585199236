import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18n from '../../i18n';
import { ThemeContext, themes } from '../../themes/ThemeContext';
import stylesNordr from './ShortFacts.nordr.module.scss';
import stylesFolkhem from './ShortFacts.folkhem.module.scss';

const getStyles = (theme) => ({
        [themes.NORDR]: stylesNordr,
        [themes.FOLKHEM]: stylesFolkhem,
    }[theme]
);

class ShortFacts extends React.PureComponent {
    render() {
        const {
            text,
            name,
            area,
            rooms,
            livingSpace,
            price,
            saleStatus,
            statusSlug,
            salesStartText,
            modifier,
            mainProject,
            heroMessageTitle,
            heroMessageText,
            heroMessageIcon,
            heroMessageBackground,
            accessStartText,
            showButton,
        } = this.props;

        const theme = this.context;
        const styles = getStyles(theme);

        const hasMessage =
            heroMessageTitle && heroMessageText && heroMessageBackground;
        const statusModifier = hasMessage
            ? 'NoStatus'
            : statusSlug || 'NoStatus';

        return (
            <div
                className={classNames(
                    styles["ShortFacts"],
                    `ShortFacts--${statusModifier}`,
                    {
                        [styles[`ShortFacts--${modifier}`]]: modifier,
                        [styles["ShortFacts--MainProject"]]: mainProject,
                    }
                )}>
                <div className={styles["ShortFacts__Content"]}>
                    {text && <h3 className={styles["ShortFacts__Text"]}>{text}</h3>}
                    {mainProject && (
                        <div className={styles["ShortFacts__MainProject"]}>
                            {i18n.t('partOf', '')}:&nbsp;
                            <span>{mainProject}</span>
                        </div>
                    )}
                    {name && <div className={styles["ShortFacts__Name"]}>{name}</div>}
                    {area && <div className={styles["ShortFacts__Area"]}>{area}</div>}
                    <table className={styles["ShortFacts__Table"]}>
                        <tbody>
                            {rooms && (
                                <tr>
                                    <td className={styles["ShortFacts__TableCell"]}>
                                        {i18n.t('shortFacts.rooms')}
                                    </td>
                                    <td className={styles["ShortFacts__TableCell"] + " " + styles["ShortFacts__TableCell--Value"]}>
                                        {rooms}
                                    </td>
                                </tr>
                            )}
                            {livingSpace && (
                                <tr>
                                    <td className={styles["ShortFacts__TableCell"]}>
                                        {i18n.t('shortFacts.area')}
                                    </td>
                                    <td className={styles["ShortFacts__TableCell"] + " " + styles["ShortFacts__TableCell--Value"]}>
                                        {livingSpace}
                                    </td>
                                </tr>
                            )}
                            {!!price && (
                                <tr>
                                    <td className={styles["ShortFacts__TableCell"]}>
                                        {i18n.t('shortFacts.price')}
                                    </td>
                                    <td className={styles["ShortFacts__TableCell"] + " " + styles["ShortFacts__TableCell--Value"]}>
                                        {price}
                                    </td>
                                </tr>
                            )}
                            {!!accessStartText && (
                                <tr>
                                    <td className={styles["ShortFacts__TableCell"]}>
                                        {i18n.t('shortFacts.accessStart')}
                                    </td>
                                    <td className={styles["ShortFacts__TableCell"] + " " + styles["ShortFacts__TableCell--Value"]}>
                                        {accessStartText}
                                    </td>
                                </tr>
                            )}
                            {saleStatus && (
                                <tr className={styles["ShortFacts__TableRow--SaleStatus"]}>
                                    <td className={styles["ShortFacts__TableCell"]}>
                                        {i18n.t('shortFacts.status')}
                                    </td>
                                    <td className={styles["ShortFacts__TableCell"] + " " + styles["ShortFacts__TableCell--Value"]}>
                                        {saleStatus}
                                    </td>
                                </tr>
                            )}
                            {(statusSlug === 'upcoming' ||
                                saleStatus === 'Kommande') &&
                                salesStartText && (
                                <tr>
                                    <td className={styles["ShortFacts__TableCell"]}>
                                        {i18n.t('shortFacts.salesStart')}
                                    </td>
                                    <td className={styles["ShortFacts__TableCell"] + " " + styles["ShortFacts__TableCell--Value"]}>
                                        {salesStartText}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {showButton && (
                        <div className={styles["ShortFacts__Button"]}>
                            {i18n.t('shortFacts.buttonText')}
                        </div>
                    )}
                </div>
                {heroMessageTitle && heroMessageText && heroMessageBackground && (
                    <div className={styles["ShortFacts__MessageWrapper"]}>
                        <div
                            className={`${styles['ShortFacts__Message']} ${styles['ShortFacts__Message--'+heroMessageBackground]} ${styles['ShortFacts__Message--'+heroMessageIcon]}}`}>
                            <div className={styles["ShortFacts__MessageContainer"]}>
                                <div className={styles["ShortFacts__MessageContent"]}>
                                    {heroMessageTitle && (
                                        <h3 className={styles["ShortFacts__MessageTitle"]}>
                                            {heroMessageTitle}
                                        </h3>
                                    )}
                                    {heroMessageText && (
                                        <p className={styles["ShortFacts__MessageText"]}>
                                            {heroMessageText}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

ShortFacts.propTypes = {
    heroMessageBackground: PropTypes.string,
    heroMessageIcon: PropTypes.string,
    heroMessageTitle: PropTypes.string,
    heroMessageText: PropTypes.string,

    text: PropTypes.string,
    name: PropTypes.string,
    area: PropTypes.string,
    rooms: PropTypes.string,
    livingSpace: PropTypes.string,
    price: PropTypes.string,
    saleStatus: PropTypes.string,
    statusSlug: PropTypes.string,
    modifier: PropTypes.string,
    link: PropTypes.shape(),
    mainProject: PropTypes.string,
    salesStartText: PropTypes.string,

    showButton: PropTypes.bool,
};

ShortFacts.defaultProps = {
    showButton: true,
}

ShortFacts.contextType = ThemeContext;

export default ShortFacts;
